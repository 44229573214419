import React, { ComponentState, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClientService } from "src/services/clientService";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { NichqHeader } from "./Nichq-heads";






export const BSL23 = ({ BSL_23_Header, BSL_23, BSL_23_PersonalState, BSL_23_Supplement, response, interfered, isPrint, questionId }) => {

  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const navigate = useNavigate();

  const [bsl_23_Header, setBsl_23_Header] = useState<any[]>([]);
  const [bsl_23, setBsl_23] = useState<any[]>([]);
  const [bsl_23_PersonalState, setBSL_23_PersonalState] = useState<any[]>([]);
  const [bsl_23_Supplement, setBsl_23_Supplement] = useState<any[]>([]);



  useEffect(() => {

    const Bsl_23_Header = BSL_23_Header.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const listBsl_23 = BSL_23.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const listBSL_23_PersonalState = BSL_23_PersonalState.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const listBsl_23_Supplement = BSL_23_Supplement.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });


    setBsl_23_Header(Bsl_23_Header);
    setBsl_23(listBsl_23);
    setBSL_23_PersonalState(listBSL_23_PersonalState);
    setBsl_23_Supplement(listBsl_23_Supplement);





  }, [response])

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [
        ...bsl_23_Header,
        ...bsl_23,
        ...bsl_23_PersonalState,
        ...bsl_23_Supplement,
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };

  return (
    <div className="ml-3">

      <div className="mt-4 head-4">
        Borderline Symptom List 23 (BSL-23)
      </div>


      {
        bsl_23_Header.length > 0 &&
        <div className="row mt-4">
          <NichqHeader

            title={""}
            disabled={response && response.length > 0}
            subTitle={''}
            listItems={bsl_23_Header}
            setListItems={setBsl_23_Header}
          />
        </div>
      }

      <div>
        Please follow these instructions when answering the questionnaire:In the following table you
        will find a set of difficulties and problems which possibly describe you. Please work through
        the questionnaire and decide how much you suffered from each problem in the course of the
        last week. In case you have no feelings at all at the present moment, please answer according
        to how you think you might have felt. Please answer honestly.
        <b> All questions refer to the last
          week. If you felt different ways at different times in the week, give a rating for how
          things were for you on average.</b>
      </div>
      <div>
        <b>
          Please be sure to answer each question.
        </b>
      </div>


      <div>
        <b>In the course of last week...</b>
      </div>

      {
        bsl_23.length > 0 &&
        <div className="row mt-4">
          <NichqHeader

            title={""}
            disabled={response && response.length > 0}
            subTitle={''}
            listItems={bsl_23}
            setListItems={setBsl_23}
          />
        </div>
      }

      <div>
        Now we would like to know in addition the quality of your <b>overall</b> personal state in the course
        of the last week. 0% means <b>absolutely down</b>, 100% means <b>excellent</b>. Please check the percentage
        which comes closest.
      </div>

      {
        bsl_23_PersonalState.length > 0 &&
        <div className="row mt-4">
          <NichqHeader

            title={""}
            disabled={response && response.length > 0}
            subTitle={''}
            listItems={bsl_23_PersonalState}
            setListItems={setBSL_23_PersonalState}
          />
        </div>
      }
      <div> 
        <center>(0%= very bad)
       &lt; 
       --------------------------------------------------
       &gt; (100%= excellent)</center>
       
      </div>


      <div className="mt-4 head-4">
        BSL - Supplement: Items for Assessing Behavior
      </div>


      <div>
        <b>During the last week.....</b>
      </div>


      {
        bsl_23_Supplement.length > 0 &&
        <div className="row mt-4">
          <NichqHeader

            title={""}
            disabled={response && response.length > 0}
            subTitle={''}
            listItems={bsl_23_Supplement}
            setListItems={setBsl_23_Supplement}
          />
        </div>

      }

      <div>
        <b>
          Please double-check for missing answers
          <br/>
        </b>
      </div>
      <div>
        <b>
          WE THANK YOU VERY MUCH FOR YOUR PARTICIPATION!
          <br/>
          PLEASE RETURN THE QUESTIONNAIRE TO YOUR THERAPIST
        </b>
      </div>



      {state == null && !isPrint ? (
        <div className="text-start mt-3 col-12">
          <button onClick={handleSubmit} className="submit_phq ">
            Submit
          </button>
        </div>
      ) : (
        ""
      )}






    </div>

  )

} 